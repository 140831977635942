@font-face {
  font-family: 'MountainsOfChristmas';
  src: local('MountainsOfChristmas'), url(./fonts/MountainsofChristmas-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'MountainsOfChristmas';
    font-weight: 900;
    src: local('MountainsOfChristmas'), url(./fonts/MountainsofChristmas-Bold.ttf) format('truetype');
}

h1 {
  font-family: MountainsOfChristmas, Arial, serif;
  text-align: center;
}